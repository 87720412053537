<template>
  <div class="ul_wrapper">
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品特点</span>
      <div class="dec-wrapper">
        <p>纯信用、无抵押、免担保</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品详情</span>
      <div class="dec-wrapper">
        <p><strong>授信额度范围：</strong>1000万以内；</p>
        <p><strong>借款期限：</strong>3个月（后期可随借随还）；</p>
        <p><strong>收费标准</strong></p>
        <table class="gridtable" border="1" cellspacing="0">
            <tr>
                <th>资金方</th>
                <th>收费项目</th>
                <th>价格(年化)</th>
                <th>收费主体</th>
                <th>备注</th>
            </tr>
            <tr>
                <td rowspan="4">三湘银行</td>
                <td>利息</td>
                <td>9%</td>
                <td>三湘银行</td>
                <td>/</td>
            </tr>
            <tr>

                <td>增信服务费</td>
                <td>3%</td>
                <td rowspan="2">大大买钢</td>
                <td style="color:red">推广期下浮至2%</td>
            </tr>
            <tr>

                <td>软件服务费</td>
                <td>12000元</td>
                <td>按年度收取</td>
            </tr>
            <tr>
                <td>履约保证金</td>
                <td>融资金额的10%</td>
                <td>大大买钢</td>
                <td>大大买钢</td>
            </tr>
        </table>
        <p style="margin-bottom:30px"><strong>实际费用测算：</strong>（9%+2%）/（1-10%）=12.22%（不含软件费）</p>
        <p><strong>资料与协议</strong></p>
        <p>1、预审资料：营业执照、银行流水明细、进销发票明细、资产证明；</p>
        <p>2、授信资料：开户许可证、机构信用代码证（中征码）、法人身份证、公司最新章程、财务报表、银行对账单；</p>
        <p>3、需签协议：授信申请书、法人签字样本、股东会决议、股东签字、企业、个人版征信授权书、法人使用个人名章承诺书、SAAS服务协议、融资合作服务协议、进销项数据采集代办委托书、银行借款协议等。</p>
        <p><strong>操作流程</strong></p>
        <img class="img_1" src="../../../../assets/img/sanxiangImg.png" alt="大大买钢">
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品要求</span>
      <div class="dec-wrapper">
        <p>1、注册期限：企业成立时间在1年以上; </p>
        <p>2、销售规模：近6个月内，市场型客户月均销售规模,大于2000吨，终端型客户月均销售规模大于1000吨;</p>
        <p>3、净资产能力：平台客户股东或实际控制人提供的净资产规模大于100万元;</p>
        <p>4、征信要求：企业法人代表和实际控制人的征信当前不能逾期,近12个月不能有M2，近24个月累计逾期次数不能超过6次,不能有冻结、止付、呆账、核销状态,近 6 个月不能在非银机构查询征信,信用贷款不超过3 笔,不能有小贷查询记录。企业征信当前不能逾期,不能有冻结、止付、呆账、核销状态。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">适应地区</span>
      <div class="dec-wrapper">
        <p>全国地区</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.ul_wrapper {
  width: 100%;
  .li_wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    .icon_circle {
      width: 15px;
      height: 15px;
      left: -6px;
      position: absolute;
      top: 7px;
      z-index: 5;
    }
    .li_title {
      width: 124px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #333333;
      border: 2px solid #fbd293;
      text-align: center;
      margin: 5px 0 5px;
      left: 25px;
      top: 0;
      display: inline-block;
      -moz-border-radius: 75px 75px 75px 75px;
      -webkit-border-radius: 75px 75px 75px 75px;
      border-radius: 75px 75px 75px 75px;
      margin:0 25px;
    }
    .dec-wrapper {
    //   display: flex;
    //   align-items: center;
      p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        line-height: 30px;
        max-width: 1000px;
      }
      .img_1{
          width: 717px;
          height: 369px;
      }
      table.gridtable {
        font-family: verdana,arial,sans-serif;
          font-size:11px;
         color:#333333;
         border-width: 1px;
        border-color: #666666;
         border-collapse: collapse;
         width: 586px;
         text-align: center;
     }
     table.gridtable th {
        border-width: 1px;
         padding: 8px;
        border-style: solid;
         border-color: #666666;
         background-color: #dedede;
     }
     table.gridtable td {
          border-width: 1px;
         padding: 8px;
         border-style: solid;
         border-color: #666666;
        background-color: #ffffff;
     }
    }
  }
}
</style>
