<template>
  <div class="ul_wrapper">
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品特点</span>
      <div class="dec-wrapper">
        <p>付票收钱，方便资金流转</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品详情</span>
      <div class="dec-wrapper">
        <p>1、注册为银承库会员用户；</p>
        <p>2、按每日实际贴现利率报价在平台上进行贴现处理；</p>
        <p>3、可在银承库进行挂失、大额行号查询。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品要求</span>
      <div class="dec-wrapper">
        <p>1、需开具的电子银行承兑汇票;  </p>
        <p>2、入围银承库要求的开票行,可根据国股、城商、农商、外资、农合、农信及村镇银行的性质进行贴现操作；</p>
        <p>3、直接对接银承库平台，注册为其会员单位。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">适应地区</span>
      <div class="dec-wrapper">
        <p>全国地区</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.ul_wrapper {
  width: 100%;
  .li_wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    .icon_circle {
      width: 15px;
      height: 15px;
      left: -6px;
      position: absolute;
      top: 7px;
      z-index: 5;
    }
    .li_title {
      width: 124px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #333333;
      border: 2px solid #fbd293;
      text-align: center;
      margin: 5px 0 5px;
      left: 25px;
      top: 0;
      display: inline-block;
      -moz-border-radius: 75px 75px 75px 75px;
      -webkit-border-radius: 75px 75px 75px 75px;
      border-radius: 75px 75px 75px 75px;
      margin:0 25px;
    }
    .dec-wrapper {
    //   display: flex;
    //   align-items: center;
      p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        line-height: 30px;
        max-width: 1000px;
      }
      .img_1{
          width: 717px;
          height: 369px;
      }
      table.gridtable {
        font-family: verdana,arial,sans-serif;
          font-size:11px;
         color:#333333;
         border-width: 1px;
        border-color: #666666;
         border-collapse: collapse;
         width: 586px;
         text-align: center;
     }
     table.gridtable th {
        border-width: 1px;
         padding: 8px;
        border-style: solid;
         border-color: #666666;
         background-color: #dedede;
     }
     table.gridtable td {
          border-width: 1px;
         padding: 8px;
         border-style: solid;
         border-color: #666666;
        background-color: #ffffff;
     }
    }
  }
}
</style>
