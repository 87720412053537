<template>
  <div class="ul_wrapper">
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品特点</span>
      <div class="dec-wrapper">
        <p>无资产担保，免抵押，持续累计信用，全国首创；</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品详情</span>
      <div class="dec-wrapper">
        <p>大大金条保理贷（中汇泽商业保理）是大大买钢平台根据平台用户的资产、信用、经营能力、SAAS系统有效交易数据及其公司法人或实际控制人的担保能力进行综合评估后，推荐并同意平台用户向中汇泽商业保理进行保理融资，平台用户将下游单位的应收账款转让至中汇泽商业保理的金融产品</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品要求</span>
      <div class="dec-wrapper">
        <p>1、注册期限：企业成立时间在6个月以上;</p>
        <p>2、销售规模：近6个月内，市场型客户月均销售规模大于2000吨，终端型客户月均销售规模大于1000吨;</p>
        <p>3、净资产能力：平台客户股东或实际控制人提供的净资产规模大于100万元; </p>
        <p>4、征信要求：企业法人代表和实际控制人的征信当前不能逾期；近12个月不能有M2，近24个月累计逾期次数不能超过6次；不能有冻结、止付、代账、核销状态；近 6 个月不能在非银机构查询征信；信用贷款不超过3 笔；不能有小贷查询记录。企业征信当前不能逾期；不能有冻结、止付、呆账、核销状态。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">适应地区</span>
      <div class="dec-wrapper">
        <p>全国地区</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.ul_wrapper {
  width: 100%;
  .li_wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    .icon_circle {
      width: 15px;
      height: 15px;
      left: -6px;
      position: absolute;
      top: 7px;
      z-index: 5;
    }
    .li_title {
      width: 124px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #333333;
      border: 2px solid #fbd293;
      text-align: center;
      margin: 5px 0 5px;
      left: 25px;
      top: 0;
      display: inline-block;
      -moz-border-radius: 75px 75px 75px 75px;
      -webkit-border-radius: 75px 75px 75px 75px;
      border-radius: 75px 75px 75px 75px;
      margin:0 25px;
    }
    .dec-wrapper {
    //   display: flex;
    //   align-items: center;
      p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        line-height: 30px;
        max-width: 1000px;
      }
      .img_1{
          width: 717px;
          height: 369px;
      }
      table.gridtable {
        font-family: verdana,arial,sans-serif;
          font-size:11px;
         color:#333333;
         border-width: 1px;
        border-color: #666666;
         border-collapse: collapse;
         width: 586px;
         text-align: center;
     }
     table.gridtable th {
        border-width: 1px;
         padding: 8px;
        border-style: solid;
         border-color: #666666;
         background-color: #dedede;
     }
     table.gridtable td {
          border-width: 1px;
         padding: 8px;
         border-style: solid;
         border-color: #666666;
        background-color: #ffffff;
     }
    }
  }
}
</style>
