<template>
  <div class="ul_wrapper">
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品特点</span>
      <div class="dec-wrapper">
        <p>纯信用、无抵押、免担保</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品详情</span>
      <div class="dec-wrapper">
        <p>大大买钢与平安银行合作，面向开具增值税发票的企业客户，只要与平安银行认可的优质企业合作稳定，或与企业自身下游交易对手合作稳定，交易频繁，即可申请平安银行“税金贷”。请扫描如下二维码进行申请。</p>
        <img src="../../../../assets/img/app_downLoad.png" alt="大大买钢">
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品要求</span>
      <div class="dec-wrapper">
        <p>1、申请人只能为企业法人或者占股前两位的股东（占股30%以上），且入股时间需满 18个月;</p>
        <p>2、所经营企业为增值税纳税人（含一般纳税人和小规模纳税人）;</p>
        <p>3、如申请人为法人：企业近 18个月内法定代表人未发生变动, 如申请人为第一、第二大股东：需在近 18 个月内保持第一或第二大股东位置, 同一家企业只能有一个有效状态的申请人;</p>
        <p>4、企业有 18 个月开票记录, 且能至少通过线上采集连续 12 个月的增值税开票记录（不含上报本月）, 最近 12 个月的开票量同比，及最近 6 个月的开票量环比下降不能同时超过30%, 最近 12 个月开票记录为 0 的月份不能超过 3 次（不含申请本月）; </p>
        <p>5、个人负债率不超过 40%, （本次授信测试额+个人其他个人经营性负债+最近6个月信用卡平均使用余额之和）/最近一年发票销售收入</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">适应地区</span>
      <div class="dec-wrapper">
        <p>全国地区</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.ul_wrapper {
  width: 100%;
  .li_wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    .icon_circle {
      width: 15px;
      height: 15px;
      left: -6px;
      position: absolute;
      top: 7px;
      z-index: 5;
    }
    .li_title {
      width: 124px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #333333;
      border: 2px solid #fbd293;
      text-align: center;
      margin: 5px 0 5px;
      left: 25px;
      top: 0;
      display: inline-block;
      -moz-border-radius: 75px 75px 75px 75px;
      -webkit-border-radius: 75px 75px 75px 75px;
      border-radius: 75px 75px 75px 75px;
      margin:0 25px;
    }
    .dec-wrapper {
    //   display: flex;
    //   align-items: center;
      p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        line-height: 30px;
        max-width: 1000px;
      }
      .img_1{
          width: 717px;
          height: 369px;
      }
      table.gridtable {
        font-family: verdana,arial,sans-serif;
          font-size:11px;
         color:#333333;
         border-width: 1px;
        border-color: #666666;
         border-collapse: collapse;
         width: 586px;
         text-align: center;
     }
     table.gridtable th {
        border-width: 1px;
         padding: 8px;
        border-style: solid;
         border-color: #666666;
         background-color: #dedede;
     }
     table.gridtable td {
          border-width: 1px;
         padding: 8px;
         border-style: solid;
         border-color: #666666;
        background-color: #ffffff;
     }
    }
  }
}
</style>
