<template>
  <div class="wrapper">
    <common-head></common-head>
    <div class="DetailsTop">
      <div class="DetailsTop_content">
        <div class="content_top">
          <img
            src="../../assets/img/logo.png"
            alt="大大买钢"
          />
          <span>{{ datasObject.title }} > 产品详情</span>
        </div>
        <div class="content_body">
          <div class="content_body_left">
            <h2>{{ datasObject.title }}</h2>
            <h5>
              <span>资金方:{{ datasObject.spanName }}</span>
            </h5>
            <h4>{{ datasObject.dec }}</h4>
          </div>
          <div class="content_body_right">
            <h5 v-for="item in datasObject.h5_arr" :key="item">{{ item }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="descBook">
      <div class="title">产品说明书</div>
      <div class="list">
          <div class="tip_top"></div>
          <sanxiangLoanPage v-if="id == 0"></sanxiangLoanPage>
          <zxLoanPage v-else-if="id == 1"></zxLoanPage>
          <silverDiscountMarketPage v-else-if="id == 2"></silverDiscountMarketPage>
          <taxLoanPage v-else-if="id == 3"></taxLoanPage>
          <factoringFinancePage v-else-if="id == 4"></factoringFinancePage>
          <div class="tip_bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
import commonHead from "../../components/headerMark.vue";
import sanxiangLoanPage from './components/details/sanxiangLoanPage.vue'
import zxLoanPage from './components/details/zxLoanPage.vue'
import silverDiscountMarketPage from './components/details/silverDiscountMarketPage.vue'
import taxLoanPage from './components/details/taxLoanPage.vue'
import factoringFinancePage from './components/details/factoringFinancePage.vue'
export default {
  name: "",
  components: { commonHead,sanxiangLoanPage,zxLoanPage,silverDiscountMarketPage,taxLoanPage,factoringFinancePage },
  data() {
    return {
      id:'',
      datas: [
        {
          title: "大大金条三湘贷",
          spanName: "三湘银行",
          dec: "定向在大汉供应链采购钢材的预付类金融产品",
          h5_arr: [
            "最高限额：10000000.00元",
            "最长期限：90天",
            "还款方式：到期还本付息",
            "利率：年利率 9.0000%起",
            "服务费率：2.0000%（年利率）",
            "保证金：10.00%(借款金额)",
          ],
        },
        {
          title: "大大金条中信贷",
          spanName: "中信银行",
          dec: "定向在大汉供应链采购钢材的预付类金融产品",
          h5_arr: [
            "最高限额：10000000元",
            "借款期限：90天",
            "还款方式：到期还本付息",
            "利率：年利率 6.3200%起",
            "服务费率：4.0000%（年利率）",
            "保证金：10%(借款金额)",
          ],
        },
        {
          title: "银票贴现市场",
          spanName: "银承库",
          dec: "大大买钢平台根据平台用户的银行承兑汇票贴现需求，推荐平台用户向银承库进行贴现融资的金融产品。",
          h5_arr: [
            "最高限额：0元",
            "借款期限：180天",
            "还款方式：到期还本付息",
            "利率：年利率 3.4000%起",
            "服务费率：0.0000%（年利率）",
            "保证金：0%(借款金额)",
          ],
        },
        {
          title: "大大金条税金贷",
          spanName: "平安银行",
          dec: "大大买钢推荐并同意平台用户向平安银行进行信用融资的金融产品。",
          h5_arr: [
            "最高限额：5000000元",
            "借款期限：90天",
            "还款方式：到期还本付息",
            "利率：日利率 0.0330%起",
            "服务费率：0.0000%（年利率）",
            "保证金：0%(借款金额)",
          ],
        },
        {
          title: "大大金条保理融",
          spanName: "中汇泽",
          dec: "针对EOC用户的资产负债、经营数据，通过授信模型评估后，给予向上游采购或向下游垫资业务的数据类金融产品。",
          h5_arr: [
            "最高限额：5000000元",
            "借款期限：30天",
            "还款方式：到期还本付息",
            "利率：日利率 0.0400%起",
            "服务费率：0.0000%（年利率）",
            "保证金：0%(借款金额)",
          ],
        },
      ],
      datasObject: {},
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.datasObject = this.datas[this.id];
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  .DetailsTop {
    width: 100%;
    height: 389px;
    background: url(../../assets/img/Detailstop.png)
      no-repeat center;
    .DetailsTop_content {
      width: 1200px;
      height: 389px;
      padding-top: 15px;
      margin: 0 auto;
      .content_top {
        width: 1200px;
        height: 60px;
        margin: 16px auto 0 auto;
        display: flex;
        align-items: center;
        img {
          position: relative;
          top: -6px;
          width: 173px;
          height: 59px;
        }
        span {
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }
      }
      .content_body {
        width: 800px;
        height: 220px;
        margin: 0px auto;
        margin-top: 45px;
        display: flex;
        .content_body_left {
          width: 400px;
          height: 215px;
          float: left;
          text-align: right;
          border-right: 1px solid #fff;
          padding-right: 30px;
          color: #fff;
          h2 {
            font-size: 50px;
            margin-bottom: 35px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            font-weight: normal;
          }
          h5 {
            font-weight: normal;
            span {
              width: 182px;
              height: 31px;
              border: 2px solid #fff;
              border-radius: 25px;
              padding: 5px 20px;
              font-size: 16px;
            }
          }
          h4 {
            font-weight: normal;
            font-size: 14px;
            margin-top: 40px;
          }
        }
        .content_body_right {
          width: 400px;
          height: 215px;
          float: left;
          padding-left: 30px;
          h5 {
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 0px;
            color: #fbd293;
            font-weight: normal;
          }
        }
      }
    }
  }
  .descBook {
    width: 1200px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 0 40px 25px 40px;
    .title {
      line-height: 88px;
      text-align: center;
      font-size: 28px;
      color: #333333;
    }
    .list {
        position: relative;
        // height: 500px;
        .tip_top{
            position: absolute;
            width: 15px;
            height: 15px;
            background: #fff;
            left: -4px;
            top: -7px;
            z-index: 20;
        }
        .tip_bottom{
            position: absolute;
            width: 15px;
            height: 15px;
            background: #fff;
            left: -4px;
            bottom: -8px;
            z-index: 20;
        }
    }
    .list::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      content: "";
      background-color: #fbd293;
    }
    .ul-wrapper {
      width: 1120px;
      border-left: 3px solid;
    }
  }
}
</style>
