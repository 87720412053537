<template>
  <div class="ul_wrapper">
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品特点</span>
      <div class="dec-wrapper">
        <p>纯信用、无抵押、免担保</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品详情</span>
      <div class="dec-wrapper">
        <p>1、预审资料：营业执照、银行流水明细、进销发票明细、资产证明；</p>
        <p>2、授信资料：开户许可证、机构信用代码证（中征码）、法人身份证、公司最新章程、财务报表、银行对账单；</p>
        <p>3、需签协议：授信申请书、法人签字样本、股东会决议、股东签字、企业、个人版征信授权书、法人使用个人名章承诺书、SAAS服务协议、融资合作服务协议、进销项数据采集代办委托书、银行借款协议等。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">产品要求</span>
      <div class="dec-wrapper">
        <p>1、场所要求：借款企业持有合法有效的营业执照，在分行辖内有固定经营场所，若企业经营特许行业，须取得有效的许可证书; </p>
        <p>2、经营要求：借款企业持续经营12个月（含）以上或实际控制人在本行业持续经营2年（含）以上，经营稳定; </p>
        <p>3、年龄要求：实际控制人年龄在18周岁（含）——65周岁（含）之间，实际控制人持有合法有效的身份证明和户籍证明; </p>
        <p>4、征信要求：借款企业无逾期记录，实际控制人当前无逾期，且原则上近24个月累计逾期小于6次（不含）；近 6 个月累计逾期小于3次（不含），近6个月连续逾期小于2期。</p>
      </div>
    </div>
    <div class="li_wrapper">
      <img
        class="icon_circle"
        src="https://ddmallimg.ddmg.com/public/assets/img/new_image/productService-3.jpg"
        alt="大大买钢"
      />
      <span class="li_title">适应地区</span>
      <div class="dec-wrapper">
        <p>全国地区</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.ul_wrapper {
  width: 100%;
  .li_wrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 17px;
    .icon_circle {
      width: 15px;
      height: 15px;
      left: -6px;
      position: absolute;
      top: 7px;
      z-index: 5;
    }
    .li_title {
      width: 124px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #333333;
      border: 2px solid #fbd293;
      text-align: center;
      margin: 5px 0 5px;
      left: 25px;
      top: 0;
      display: inline-block;
      -moz-border-radius: 75px 75px 75px 75px;
      -webkit-border-radius: 75px 75px 75px 75px;
      border-radius: 75px 75px 75px 75px;
      margin:0 25px;
    }
    .dec-wrapper {
    //   display: flex;
    //   align-items: center;
      p {
        font-size: 14px;
        color: #666666;
        margin: 0;
        line-height: 30px;
        max-width: 1000px;
      }
      .img_1{
          width: 717px;
          height: 369px;
      }
      table.gridtable {
        font-family: verdana,arial,sans-serif;
          font-size:11px;
         color:#333333;
         border-width: 1px;
        border-color: #666666;
         border-collapse: collapse;
         width: 586px;
         text-align: center;
     }
     table.gridtable th {
        border-width: 1px;
         padding: 8px;
        border-style: solid;
         border-color: #666666;
         background-color: #dedede;
     }
     table.gridtable td {
          border-width: 1px;
         padding: 8px;
         border-style: solid;
         border-color: #666666;
        background-color: #ffffff;
     }
    }
  }
}
</style>
